/* This file should be part of CKE Package
and will be removed once we starting using it. */

@import '../ContentEditor/ContentEditor.module.css';

.ck-restricted-editing_mode_restricted {
  margin: 0;
  padding: 0;
}

.ck-restricted-editing_mode_restricted :global input {
  pointer-events: none;
}

:global(.ck-read-only figure) {
  outline: none !important;
  border: none !important;
}

:global(.ck-read-only .ck-widget.table td.ck-editor__nested-editable:focus) {
  background: none;
  outline: none;
  box-shadow: none;
}

:global(.ck-read-only figure > img) {
  border: none !important;
  outline: none;
  pointer-events: none !important;
}

:global(.ck-read-only figure > div.ck-widget__type-around) {
  border: none !important;
  outline: none;
  pointer-events: none !important;
}

.ck-restricted-editing_mode_restricted :global .ck-read-only .ck.ck-reset_all.ck-widget__type-around {
  display: none !important;
}

.ck-restricted-editing_mode_restricted :global .ck-read-only .ck.ck-widget__selection-handle {
  display: none !important;
}

:global(.ck-read-only figure > div.ck-widget__resizer) {
  display: none !important;
}

:global(.ck-read-only figure.ck-widget) {
  transition: none !important;
}

:global(.ck-read-only .ck-widget.table .ck-editor__nested-editable:focus) {
  background: #0000000d;
  border-style: inherit;
  outline: inherit;
  outline-offset: inherit;
  box-shadow: inherit;
}

:global(.ck-read-only span.image-inline) {
  outline: none !important;
}

:global(.ck-read-only span.image-inline:not(.ck-comment-marker)) {
  border: none;
}

:global(.ck-read-only span.image-inline div.ck-widget__resizer) {
  display: none !important;
}

:global(.ck-read-only figure > a) {
  pointer-events: all;
}
