:root {
  /*
    Page dimensions (A4)
  */

  --A4PageWidth: 210mm;
  --A4PageHeight: 297mm;
  --A4PagePaddingBlock: 6mm;
  --DocumentPageInlineMargin: 20mm;
  --DocumentInlinePadding: 2mm;
  --DocumentEditorPageBackground: white;

  /*
    - text paragraph
  */
  --DocumentParagraphPaddingBottom: 10px;

  --ck-border-radius: 4px;
}
