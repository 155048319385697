.task-comment ol {
  padding-inline-start: 40px;
}

.task-comment .froala-attachments-list {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px solid #bbb;

  ul {
    list-style: none;
    padding: 0;

    li:before {
      content: '\1F4CE';
      display: inline-block;
      width: 1.3em;
    }
  }

  .froala-attachments-list_item {
    &:first-child {
      padding-top: 0;
    }

    padding: 5px 0;
  }

  a:not([href]) {
    display: none;
    cursor: pointer;
    float: right;
    color: #222222;
  }
}
